import logo from './logo.svg';
import './App.css';
import React from "react";
import Unity, {UnityContext} from "react-unity-webgl";

function App() {

  const queryParams = new URLSearchParams(window.location.search);
  const game = queryParams.get('game');

  let unityContext;
  let unityClass = 'unity-canvas';
  let unityContainerClass = 'unity-container';
  if(game === 'footballMario') {
    unityContext = new UnityContext({
      codeUrl: "/unity/footballMario/footballMario.wasm",
      dataUrl: "/unity/footballMario/footballMario.data",
      frameworkUrl: "/unity/footballMario/footballMario.framework.js",
      loaderUrl: "/unity/footballMario/footballMario.loader.js"
    });
  } else if(game === 'headTheBall') {
    unityContext = new UnityContext({
      codeUrl: "/unity/headTheBall/headTheBall.wasm",
      dataUrl: "/unity/headTheBall/headTheBall.data",
      frameworkUrl: "/unity/headTheBall/headTheBall.framework.js",
      loaderUrl: "/unity/headTheBall/headTheBall.loader.js"
    });
    unityContainerClass = 'unity-container-portrait';
  } else if(game === 'penalty') {
    unityContext = new UnityContext({
      codeUrl: "/unity/Penalty/Penalty.wasm",
      dataUrl: "/unity/Penalty/Penalty.data",
      frameworkUrl: "/unity/Penalty/Penalty.framework.js",
      loaderUrl: "/unity/Penalty/Penalty.loader.js"
    });
    unityContainerClass = 'unity-container-portrait';
  } else if(game === 'dribble') {
    unityContext = new UnityContext({
      codeUrl: "/unity/Penalty/Penalty.wasm",
      dataUrl: "/unity/Penalty/Penalty.data",
      frameworkUrl: "/unity/Penalty/Penalty.framework.js",
      loaderUrl: "/unity/Penalty/Penalty.loader.js"
    });
    unityContainerClass = 'unity-container-portrait';
  } else {
    unityContext = new UnityContext({
      codeUrl: "/unity/testgame/example-app.wasm",
      dataUrl: "/unity/testgame/example-app.data",
      frameworkUrl: "/unity/testgame/example-app.framework.js",
      loaderUrl: "/unity/testgame/example-app.loader.js"
    });
  }

  return (
    <div className="App">
      <div className={unityContainerClass}>
        <Unity className={unityClass} unityContext={unityContext}/>
      </div>
    </div>
  );
}

export default App;
